const DashboardFollowers = () => {
    return (
        <div className="dashboard-body__content">
        <div className="card common-card">
          <div className="card-body">
            <div className="follow-wrapper">
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
              <div className="follow-item">
                <div className="follow-item__author">
                  <div className="d-flex align-items-start gap-2">
                    <div className="author-details__thumb flex-shrink-0">
                      <img src="assets/images/thumbs/author-details-img.png" alt="" />
                    </div>
                    <div className="author-details__content">
                      <h6 className="author-details__name font-18 mb-2">Oviousdev</h6>
                      <ul className="badge-list badge-list--sm flx-align gap-1 mt-3 ms-0">
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge1.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge2.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge3.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge4.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge5.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge6.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge7.png" alt="" />
                        </li>
                        <li
                          className="badge-list__item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Badge Info"
                        >
                          <img src="assets/images/thumbs/badge8.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="follow-item__meta">
                  <strong className="font-11 fw-600 text-heading">
                    77 Items
                    <br />
                    100 Followers
                    <br />
                    Member Since: September 2018
                    <br />
                    Available for freelance work
                  </strong>
                </div>
                <div className="follow-item__sales">
                  <div className="sales">
                    <span className="sales__text mb-1 font-13 text-heading fw-500">
                      Sales
                    </span>
                    <h6 className="sales__amount mb-0 font-body">15,830</h6>
                    <ul className="star-rating mt-2">
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                      <li className="star-rating__item font-11">
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <span className="star-rating__text text-heading font-12 fw-500">
                      {" "}
                      116 ratings
                    </span>
                  </div>
                </div>
                <button type="button" className="btn btn-main pill px-4">
                  Follow
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
}

export default DashboardFollowers;