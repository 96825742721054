import React from "react";
import Progress from "../components/Progress";
import Login from "../components/Login";
const LoginPage = () => {

  return (

    <>
      {/* Progress */}
      <Progress />

      {/* Login */}
      <Login />
    </>
  );
};

export default LoginPage;
