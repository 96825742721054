import React from "react";
import Progress from "../components/Progress";
import Register from "../components/Register";
const RegisterPage = () => {

  return (

    <>
      {/* Progress */}
      <Progress />

      {/* Login */}
      <Register />
    </>
  );
};

export default RegisterPage;
